footer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 20px;
  .container__footer {
    &-socials {
      z-index: 2;
      width: 60%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      align-self: center;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 100px;
      box-shadow: inset 1px -1px 2px 0 #5fd8fa, inset -2px -1px 1px 0px #6634da,
        inset 0px 1px 0px 0px #5fd8fa, inset -2px -1px 1px 0px #6634da;
      img {
        margin: 10px;
      }
      @media only screen and (min-width: 768px) {
        display: none;
      }
    }
    &-socialsXl {
      z-index: 2;
      display: grid;
      justify-content: space-evenly;
      align-items: center;
      align-self: center;
      grid-template: repeat(1, 1fr) / 20% 20% 20%;
      justify-content: center;
      column-gap: 15px;
      width: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media only screen and (min-width: 768px) {
        justify-content: center;
        width: 80%;
        column-gap: 15px;
        grid-template: repeat(1, 1fr) / 15% 15% 15% 5% 5% 5% 5%;
      }
      .iconPartner {
        width: 75px;
        @media only screen and (min-width: 1024px) {
          width: 100px;
        }
      }
      .iconSocial {
        width: 40px;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}
