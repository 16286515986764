.container__services {
  margin-top: 15vh;
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    width: 60%;
  }

  h1 {
    strong {
      background: linear-gradient(90.13deg, #5fd8fa 0.05%, #6634da 105.95%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  video {
    object-fit: cover;
    width: 100%;
    border-radius: 7px;
    border: double 2px transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to top, #6634da, #4e74ee, #5fd8fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
