.container__pageEvents {
  display: flex;
  flex-direction: column;
  row-gap: 5vh;
  padding-bottom: 10vh;
  @media only screen and (min-width: 1024px) {
    row-gap: 15vh;
  }
}
.container__events {
  height: 100vh;
  background-image: url("../../../public/assets/bg-events.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: -85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  h1 {
    font-size: 35px;
    em {
      font-size: 30px !important;
    }
  }
  h1,
  p {
    width: 80%;
  }
  em {
    font-size: 30px;
    font-style: normal;
  }
  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 40px;
      em {
        font-size: 35px;
      }
    }

    background-position-x: center;
  }
}

.container__gitex {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  &-header {
    text-align: end;
    @media only screen and (min-width: 768px) {
      width: 70%;
      align-self: flex-end;
    }
    @media only screen and (min-width: 1024px) {
      width: 40%;
      align-self: normal;
      h2 {
        margin-block-start: 0;
      }
    }
    .container__gitex-register {
      display: none;
      @media only screen and (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        &-btn {
          margin: 0;
          align-self: flex-end;
          padding: 1% 5%;
          width: fit-content;
          display: flex;
          text-align: center;
          justify-content: center;
          box-sizing: content-box;
          border-width: 1px;
          border-radius: 25px;
          box-shadow: inset 1px -1px 2px 0 #5fd8fa,
            inset -2px -1px 1px 0px #6634da, inset 0px 1px 0px 0px #5fd8fa,
            inset -2px -1px 1px 0px #6634da;
        }
      }
    }
  }

  iframe {
    width: 100%;
    border-radius: 7px;
    border: double 2px transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to top, #6634da, #4e74ee, #5fd8fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  @media only screen and (min-width: 768px) {
    iframe {
      height: 40vh;
    }
  }
  @media only screen and (min-width: 1024px) {
    flex-direction: row-reverse;
    justify-content: space-between;
    iframe {
      width: 55%;
      height: 400px;
    }
  }
}
.container__gitex-register {
  &-btn {
    padding: 3% 10%;
    width: fit-content;
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: center;
    box-sizing: content-box;
    border-width: 1px;
    border-radius: 25px;
    box-shadow: inset 1px -1px 2px 0 #5fd8fa, inset -2px -1px 1px 0px #6634da,
      inset 0px 1px 0px 0px #5fd8fa, inset -2px -1px 1px 0px #6634da;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.container__fifa {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  h2 {
    strong {
      background: linear-gradient(90.13deg, #5fd8fa 0.05%, #6634da 105.95%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  iframe {
    width: 100%;
    border-radius: 7px;
    border: double 2px transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to top, #6634da, #4e74ee, #5fd8fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .container__fifa-header {
      width: 40%;
    }
    iframe {
      width: 50%;
      height: 400px;
      object-fit: cover;
    }
  }
}
.container__tournament {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: end;

  img {
    width: 100%;
    object-fit: contain;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .container__tournament-header {
      width: 50%;
    }
    img {
      width: 45%;
      //   height: 400px;
      object-fit: cover;
    }
  }
}
