.container__ourAmbition {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  min-height: 60vh;
  padding-top: 10vh;
  background-image: url("../../../public/assets/bg-ourAmbition.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media only screen and (max-width: 768px) {
    background-position-x: -60vh;
  }
  @media only screen and (min-width: 768px) {
    min-height: 70vh;
    h2,
    p {
      width: 60%;
    }
  }
}
