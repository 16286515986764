.container__contact {
  margin-bottom: 10vh;
  margin-top: 10vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../../public/assets/img-vr.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  form {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
    @media only screen and (min-width: 1024px) {
      width: 45%;
    }
    .submit-btn {
      color: white;
      border: none;
      text-align: center;
      border-radius: 25px;
      height: 35px;
      width: 45%;
      align-self: flex-end;
      background: linear-gradient(
        91.72deg,
        #5fd8fa 9.11%,
        #4e74ee 58.93%,
        #6634da 107.73%
      );
    }
    div {
      display: flex;
      align-items: center;
      border-radius: 25px;
      background: rgba(3, 4, 5, 0.8);
      box-shadow: inset 1px -1px 2px 0 #5fd8fa, inset -2px -1px 1px 0px #6634da,
        inset 0px 1px 0px 0px #5fd8fa, inset -2px -1px 1px 0px #6634da;
      padding: 0 25px;
      input {
        height: 50px;
        padding: 0 10px;
      }
      textarea {
        height: 200px;
        padding: 25px 10px;
      }
      input,
      textarea {
        outline: none;
        width: 100%;
        background: transparent;
        font-weight: 700;
        font-size: 12px;

        color: white;
        border: 0;
      }
    }

    @media only screen and (min-width: 768px) {
      // form {
      //   width: 70% !important;
      // }

      .submit-btn {
        width: 45%;
      }
    }
    @media only screen and (min-width: 1024px) {
      // form {
      //   width: 40%;
      // }

      .submit-btn {
        width: 40%;
      }
    }
  }

  .form-thanks {
    display: flex;
    color: white;
    width: fit-content;
    padding: 15px;
    border-radius: 15px;
    background: rgba(3, 4, 5, 0.8);
    box-shadow: inset 1px -1px 2px 0 #5fd8fa, inset -2px -1px 1px 0px #6634da,
      inset 0px 1px 0px 0px #5fd8fa, inset -2px -1px 1px 0px #6634da;
  }
}
