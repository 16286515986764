.container__ourTeam {
  display: flex;
  flex-direction: column;
  padding-top: 10vh;
  text-align: center !important;
  p {
    width: 90%;
    align-self: center;
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
  }

  &-cards {
    margin-top: 10vh;
    display: grid;
    grid-template: repeat(3, 2fr) / 45% 45%;
    row-gap: 5%;
    justify-content: space-between;
    @media only screen and (min-width: 768px) {
      grid-template: repeat(3, 2fr) / 35% 35%;
      justify-content: space-around;
    }
    @media only screen and (min-width: 1024px) {
      justify-content: space-evenly;
      grid-template: repeat(3, 2fr) / 15% 15% 15% 15%;
      row-gap: 5%;
      .cardTeam {
        cursor: default !important;
      }
    }
    .cardTeam {
      text-align: center;
      position: relative;
      cursor: pointer;
      color: white;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 1024px) {
        &:hover > img {
          filter: brightness(0.5);
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 100%;
        border: double 2px transparent;
        background-image: linear-gradient(white, white),
          linear-gradient(to top, #6634da, #4e74ee, #5fd8fa);
        background-origin: border-box;
        background-clip: content-box, border-box;
      }
      &-legend {
        text-align: center;
        display: flex;
        flex-direction: column;
        top: 35%;
        align-self: center;
        position: absolute;
        opacity: 0;
        span {
          font-size: 12px;
        }
        @media only screen and (min-width: 768px) {
          span {
            font-size: 14px;
          }
        }
        @media only screen and (min-width: 1024px) {
          opacity: 1;
          position: relative;
          top: auto;
        }
      }
    }
    .cardTeam:hover > .cardTeam-legend {
      opacity: 1;
    }
  }
}
